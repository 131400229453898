import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import Gallery from "../components/Gallery";

// eslint-disable-next-line
export const ProjectenPageTemplate = class extends React.Component {

  componentDidMount() {
    let root = document.documentElement;
    root.style.setProperty('--tp-navbar-color', '#374836');
  }
  render() {
    return (
      <div>
        <section className="header compact no-background">
          <div className="container">
            <h1 className="title">PROJECTEN</h1>
          </div>
        </section>
        <section className="content container mt-5 mb-6"> 
          <div className="row">
            <div className="col-md-12">
              <Gallery images={this.props.fotosProjecten} />
            </div>
          </div>
        </section>
      </div>
    );
  }
}

ProjectenPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const ProjectenPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <ProjectenPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        fotosProjecten={post.frontmatter.fotosProjecten}
      />
    </Layout>
  );
};

ProjectenPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ProjectenPage;

export const projectenPageQuery = graphql`
  query ProjectenPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        fotosProjecten {
          image {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 60) {
                ...GatsbyImageSharpFluid
              }
              original {
                width
                height
              }
            }
          }
          text
        }
      }
    }
  }
`;
